/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useState, useEffect } from 'react';
import {
  Routes, Route, Link, useNavigate,
} from 'react-router-dom';
import '../style/components/message.css';
import { Logo } from '../img/img';

function Alert({ response }) {
  const [openPopUp, setOpenPopUp] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (response?.status) {
      setOpenPopUp(true);
    }
  }, [response]);

  return (
    <>
      {openPopUp ? (
        <div className="message">
          <div className="messageContainer">
            <img src={Logo} alt="logo" />
            <h4>{response?.status === 'ok' ? response?.message : response?.errorMessage}</h4>
            <button
              type="button"
              onClick={() => {
                setOpenPopUp(!openPopUp);
                if (response?.status === 'ok') {
                  navigate('/');
                }
              }}
            >
              {response?.status === 'ok' ? 'Gracias' : 'Aceptar'}
            </button>
          </div>
        </div>
      ) : ''}
    </>
  );
}

export default Alert;
