import { React, useEffect, useContext, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { Context } from "../components/Context";
import { endpointApi } from "../endpoints";
import { checkLevel, htmlDecode } from "../service";
import Slider from "react-slick";
import Footer from "../components/Footer";
import PopUpAbout from "../components/PopUpAbout";
import AOS from "aos";
import "aos/dist/aos.css";
import "../style/site/home.css";
import {
  Logo,
  PortadaHome,
  PortadaHomeThree,
  PortadaHomeFive,
  PortadaHomeSix,
  PortadaHomeSeven,
  PortadaHomeEight,
  PortadaHomeNine,
  CircleHome,
  ArrowSliderRight,
  ArrowSliderLeft,
  IconVision,
  Principios,
  Mision,
  LogoCertificateOne,
  LogoCertificateThree,
  LogoCertificateFive,
} from "../img/img";
import { Helmet } from "react-helmet";

export default function LandingSelection(props) {
  useEffect(() => {
    AOS.init();
  }, []);
  function SliderNextArrow(props) {
    const { onClick } = props;
    return (
      <button onClick={onClick} className="arrowSliderRight" type="button">
        <img src={ArrowSliderRight} alt="" />
      </button>
    );
  }

  function SliderPrevArrow(props) {
    const { onClick } = props;
    return (
      <button onClick={onClick} className="arrowSliderLeft" type="button">
        <img src={ArrowSliderLeft} alt="" />
      </button>
    );
  }

  const sliderHome = {
    infinite: true,
    speed: 2500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    dots: false,
    autoplay: true,
    slidesToScroll: 1,
  };

  const sliderCertificate = {
    infinite: true,
    speed: 2500,
    autoplaySpeed: 1500,
    slidesToShow: 5,
    nextArrow: false,
    prevArrow: false,
    dots: false,
    autoplay: true,
    centerPadding: "70px",
    centerMode: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "110px",
          centerMode: true,
        },
      },
    ],
  };

  const [vision, setOpenVision] = useState(false);
  const [mision, setOpenMision] = useState(false);
  const [principios, setOpenPrincipios] = useState(false);
  const [politicas, setOpenPoliticas] = useState(false);

  const { data, setData } = useContext(Context);
  const [highlight, setHighlights] = useState();
  const params = useParams();
  const { level } = params;

  return (
    <>
      <Helmet>
        <title>{'Colegio St. George\'s'}</title>
        <meta name="title" content={'Colegio St. George\'s'} />
        <meta property="og:title" content={'Colegio St. George\'s'} />
        <meta property="twitter:title" content={'Colegio St. George\'s'} />
      </Helmet>
      <div id="home">
        <img
          src={CircleHome}
          alt="Circulo decorativo"
          className="circlePortadaHome"
        ></img>
        <div
          className="headerLanding"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="500"
        >
          <img
            src={Logo}
            alt="Logo St George's School"
            className="logoHeaderHome"
          ></img>
        </div>
        <div className="portadaHome">
          <Slider {...sliderHome}>
            <img src={PortadaHomeNine} alt="Actividad en el colegio" className="portadaText"></img>
            <img src={PortadaHome} alt="Actividad en el colegio"></img>
            <img src={PortadaHomeFive} alt="Actividad en el colegio"></img>
            <img src={PortadaHomeSeven} alt="Actividad en el colegio"></img>
            <img src={PortadaHomeEight} alt="Actividad en el colegio"></img>
          </Slider>
        </div>
        <div className="portadaSelectionSite">
          <a href="/primaria" className="selectionSite primary">
            <div className="titlePrimary">
              <div>
                <p>CONOCÉ SOBRE</p>
                <h1>PRIMARIA</h1>
              </div>
              <a href="/primaria" className="button-white">
                Ver más
              </a>
            </div>
          </a>
          <a href='/secundaria' className="selectionSite secondary">
            <div className="titlePrimary">
              <div>
                <p>CONOCÉ SOBRE</p>
                <h1>SECUNDARIA</h1>
              </div>
              <Link to="/secundaria" className="button-white">
                Ver más
              </Link>
            </div>
          </a>
        </div>

        <div className="selectView">
          {data?.highlights?.map((highlight) => {
            if (checkLevel(highlight.educational_stage_id, level)) {
              return (
                <div
                  className="containerSelectView activities"
                  style={{
                    backgroundImage: `url(${endpointApi}${highlight?.file.webp_url})`,
                  }}
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-sine"
                >
                  <h3>{highlight?.title}</h3>
                  <Link to={highlight?.link} className="button-white">
                    Ver más
                  </Link>
                </div>
              );
            }
          })}
        </div>

        <div className="aboutUs">
          <h2>Nosotros</h2>
          <div className="containerAboutUs">
            <div className="card-aboutUs vision">
              <img src={IconVision} alt="Icono"></img>
              <h3>Visión</h3>
              <p>
                Nos enfocamos en ser una institución de sólida formación
                integral, referente en la comunidad educativa. Nos caracteriza
                la innovación, como también la superación permanente.
              </p>
              <button
                onClick={() => {
                  setOpenVision(!vision);
                }}
                className={
                  vision === true
                    ? "button-green openPopUpVision"
                    : "button-green"
                }
                type="button"
              >
                Ver más
              </button>
              <PopUpAbout
                imgPopup={Principios}
                titlePopup="Visión"
                parrafoPopup="Nos enfocamos en ser una institución de sólida formación integral, referente en la comunidad educativa. Nos caracteriza la innovación, como también la superación permanente."
                parrafoPopupTwo="Nos enfocamos en ser una institución de sólida formación integral, referente en la comunidad educativa. Nos caracteriza la innovación, como también la superación permanente. Nuestra meta es formar personas con espíritu crítico y creativo, de mentalidad abierta, empáticas, equilibradas, audaces, informadas, responsables de sus procesos de aprendizaje, que logren aportar a la construcción colectiva del conocimiento, respeten las diferencias y se comprometan con la sociedad, la diversidad cultural y el medio ambiente."
                setOpenVision={setOpenVision}
              />
            </div>

            <div className="card-aboutUs mision">
              <img src={Mision} alt="Icono"></img>
              <h3>Misión</h3>
              <p>
                Somos una comunidad educativa bilingüe, laica y abierta al mundo
                que centra su manera de pensar y accionar en el reconocimiento
                del valor de cada uno de sus integrantes, dónde la contención
                emocional es vital para la consecución de  aprendizajes
                significativos.
              </p>
              <button
                onClick={() => {
                  setOpenMision(!mision);
                }}
                className={
                  mision === true
                    ? "button-green openPopUpVision"
                    : "button-green"
                }
                type="button"
              >
                Ver más
              </button>
              <PopUpAbout
                imgPopup={Mision}
                titlePopup="Misión"
                parrafoPopup="Somos una comunidad educativa bilingüe, laica y abierta al mundo que centra su manera de pensar y accionar en el reconocimiento del valor de cada uno de sus integrantes, dónde la contención emocional es vital para la consecución de  aprendizajes significativos. Sobre la base de un sólido sustento académico, trabajamos diariamente para formar ciudadanos autónomos, creativos, críticos, empáticos, solidarios, y reflexivos, promoviendo la inteligencia emocional y formación integral de la persona."
                parrafoPopupTwo="Facilitamos el desarrollo de competencias que favorezcan habilidades para que nuestros estudiantes aprendan activamente durante toda la vida en función de sus intereses. Promovemos valores esenciales para la convivencia responsable y la construcción de un mundo mejor, tales como el respeto intercultural y la comprensión de la heterogeneidad."
                parrafoPopupThree="A través de la puesta en práctica de los atributos del perfil del estudiante del Bachillerato Internacional, contribuimos a la formación de articuladores de la sociedad contemporánea, desarrollando día a día nuevas herramientas y estrategias para alcanzar una inclusión adecuada y fortalecer su desempeño en el contexto local, nacional y global."
                setOpenVision={setOpenMision}
              />
            </div>

            <div className="card-aboutUs principios">
              <img src={Principios} alt="Icono"></img>
              <h3>Principios Institucionales</h3>
              <p>
                Nuestros estudiantes aprenden a aprender en un entorno de
                contención cuyos pilares son la conciencia global y el respeto
                mutuo e intercultural.
              </p>
              <button
                onClick={() => {
                  setOpenPrincipios(!principios);
                }}
                className={
                  principios === true
                    ? "button-green openPopUpVision"
                    : "button-green"
                }
                type="button"
              >
                Ver más
              </button>
              <PopUpAbout
                imgPopup={Principios}
                titlePopup="Principios"
                parrafoPopup="Nuestros estudiantes aprenden a aprender en un entorno de contención cuyos pilares son la conciencia global y el respeto mutuo e intercultural. Priorizamos la incorporación activa de contenidos, conceptos y habilidades en todas las áreas del conocimiento. Nuestro objetivo es formar personas críticas, empáticas y reflexivas, con formación académica sólida y humanitaria.  Entendemos el bilingüismo como una herramienta esencial para la adquisición de competencias lingüísticas en español e inglés y como recurso para fomentar la interculturalidad. Paralelamente, destacamos la importancia del portugués como tercera lengua con el fin de que nuestros estudiantes desarrollen habilidades comunicativas, teniendo en cuenta nuestro contexto geográfico y de este modo fortalecer la construcción de  ciudadanía global."
                setOpenVision={setOpenPrincipios}
              />
            </div>

            <div className="card-aboutUs politicas">
              <img src={Principios} alt="Icono"></img>
              <h3>Políticas Institucionales</h3>
              <p>
                Las políticas institucionales se focalizan en los acuerdos
                construídos junto al equipo de liderazgo pedagógico y los
                docentes para consolidar en documentos toda la alineación
                necesaria para los procesos de enseñanza y de aprendizaje.
              </p>
              <button
                onClick={() => {
                  setOpenPoliticas(!politicas);
                }}
                className={
                  politicas === true
                    ? "button-green openPopUpVision"
                    : "button-green"
                }
                type="button"
              >
                Ver más
              </button>
              <PopUpAbout
                imgPopup={Principios}
                titlePopup="Políticas Institucionales"
                parrafoPopup="Las políticas institucionales se focalizan en los acuerdos construídos junto al equipo de liderazgo pedagógico y los docentes para consolidar en documentos toda la alineación necesaria para los procesos de enseñanza y de aprendizaje."
                setOpenVision={setOpenPoliticas}
                titlePdf="Política de admisión"
                linkPdf='PolíticaDeAdmisión28_6.pdf'
                titlePdfTwo="Política de evaluación"
                linkPdfTwo='Política de evaluación St George 10 de diciembre.pdf'
                titlePdfThree="Política Lingüística"
                linkPdfThree="Política Lingüística 10 de diciembre.pdf"
                titlePdfFour="Política de integridad académica"
                linkPdfFour="Política de integridad académica 28.1.23.pdf"
                titlePdfFive="Política de inclusión y acceso al aprendizaje"
                linkPdfFive="Política de inclusión 10 de diciembre.pdf"
              />
            </div>
          </div>
        </div>

        <div className="certificate">
          <h4 data-aos="fade-down" data-aos-offset="300">
            Trabajamos en alianza:
          </h4>
          <div className="certificateSlider">
            <Slider {...sliderCertificate}>
              <img src={LogoCertificateOne} alt="Certificado"></img>
              <img src={LogoCertificateThree} alt="Certificado"></img>
              <img src={LogoCertificateFive} alt="Certificado"></img>
              <img src={LogoCertificateOne} alt="Certificado"></img>
              <img src={LogoCertificateThree} alt="Certificado"></img>
              <img src={LogoCertificateFive} alt="Certificado"></img>
            </Slider>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
