import { React, useState } from "react";
import Link from "antd/lib/typography/Link";
import "../style/components/popUpAbout.css";
import { Close } from "../img/img";

function PopUpAbout(props) {

    return (
        <div id="popUpAbout">
            <div className="popup">
                <div className="headerPopup">
                    <img src={props.imgPopup} alt='icono' className="iconoPopup"></img>
                    <button type='button' onClick={() => {props.setOpenVision(false);}} className="closePopUp" ><img src={Close} alt='icono'></img> </button>
                </div>
                <h2>{props.titlePopup}</h2>
                <p>{props.parrafoPopup}</p>
                <p>{props.parrafoPopupTwo}</p>
                <p>{props.parrafoPopupThree}</p>
                <div className="linksPopup">
                    <a href={props.linkPdf} download>{props.titlePdf}</a>
                    <a href={props.linkPdfTwo} download>{props.titlePdfTwo}</a>
                    <a href={props.linkPdfThree} download>{props.titlePdfThree}</a>
                    <a href={props.linkPdfFour} download>{props.titlePdfFour}</a>
                    <a href={props.linkPdfFive} download>{props.titlePdfFive}</a>
                </div>
            </div>
        </div>
    );
}

export default PopUpAbout;
