import { React, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../style/site/contact.css";
import { ContactoImagen } from "../img/img";
import { endpointContactForm } from "../endpoints";
import axios from "axios";
import Alert from "../components/Alert";
import { Helmet } from "react-helmet";

export default function Contact() {
  const [response, setResponse] = useState([]);

  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  async function onSubmitHandler(data) {
    try {
      // setLoadingState(true);
      await axios.post(endpointContactForm, { contact: data }).then((res) => {
        // setLoadingState(false);
        setResponse(res.data);
      }, (err) => err);
    } catch (error) {
      // setLoadingState(false);
      return error;
    }
  }

  return (
    <>
      <Helmet>
        <title>{'Contacto - Colegio St. George\'s'}</title>
        <meta name="title" content={'Contacto - Colegio St. George\'s'} />
        <meta property="og:title" content={'Contacto - Colegio St. George\'s'} />
        <meta property="twitter:title" content={'Contacto - Colegio St. George\'s'} />
      </Helmet>
      <Header />
      <div id="contact">
        <img src={ContactoImagen} alt='Imagen del colegio'></img>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <h3>¿Tenés alguna duda? <br></br> ¡Escribinos!</h3>
          <input {...register('name', { required: '*El campo de nombre es obligatorio' })} type='name' placeholder="Nombre y apellido"></input>
          <span>{errors.name?.message}</span>
          <input {...register('email', { required: '*El campo de email es obligatorio' })} type='email' placeholder="Email"></input>
          <span>{errors.email?.message}</span>
          <input {...register('subject', { required: '*El campo de asunto es obligatorio' })} type='subject' placeholder="Asunto"></input>
          <span>{errors.subject?.message}</span>
          <textarea {...register('message', { required: '*El campo de mensaje es obligatorio' })} name="message" placeholder="Mensaje"></textarea>
          <span>{errors.message?.message}</span>
          <input
            type="submit"
            value='ENVIAR'
            id='ENVIAR'
            className='button-contact'
          />
        </form>
        <Alert response={response} />
      </div>
      <Footer />
    </>
  );
}
