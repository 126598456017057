import { React, useEffect, useContext, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Context } from "../components/Context";
import { checkLevel, htmlDecode } from "../service";
import Slider from "react-slick";
import "../style/components/sliderGallery.css";
import { endpointApi } from "../endpoints";
import Footer from "../components/Footer"
import Header from "../components/Header"
import Card from "../components/Card"
import "../style/site/noticia.css";
import { ImagenNoticia } from "../img/img";
import { Helmet } from "react-helmet";

function Noticia() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    const { data, setData } = useContext(Context);
    const [newsItem, setNewsItem] = useState();
    const params = useParams();
    const { level } = params;
    const { id } = params;
    const location = useLocation();

    useEffect(() => {
        data?.newsItems?.forEach((item) => {
            if (
                checkLevel(item.educational_stage_id, level) &&
                item.id === id
            ) {
                setNewsItem(item);
            }
        });
    }, [location.pathname]);

    const sliderNoticia = {
        infinite: true,
        speed: 2500,
        autoplaySpeed: 1500,
        slidesToShow: 3,
        nextArrow: false,
        prevArrow: false,
        dots: false,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 1,
                    autoplay: false,
                    slidesToScroll: 1,
                }
            },
        ]
    };


    return (
        <>
            <Helmet>
                <title>{'Noticia - Colegio St. George\'s'}</title>
                <meta name="title" content={'Noticia - Colegio St. George\'s'} />
                <meta property="og:title" content={'Noticia - Colegio St. George\'s'} />
                <meta property="twitter:title" content={'Noticia - Colegio St. George\'s'} />
            </Helmet>
            <Header />
            <div id="noticia">
                <div className="containerNoticia">
                    <div className="containerImgNoticia">
                        <img src={endpointApi + newsItem?.file.webp_url} alt='Imagen del colegio'></img>
                    </div>
                    <div className="headerNoticia">
                        <h1>{newsItem?.title}</h1>
                        <span>{newsItem?.subtitle}</span>
                    </div>
                    <div className="bodyNoticia">
                        <p dangerouslySetInnerHTML={{ __html: newsItem?.description }}></p>
                    </div>
                </div>
                <div className="ultimasNoticias">
                    <h3>Ultimas noticias</h3>
                    <div className="ultimasNoticiasContainer">
                        <Slider {...sliderNoticia}>
                            {data?.newsItems?.map((item) => {
                                if (checkLevel(item.educational_stage_id, level)) {
                                    return (
                                        <Card
                                            portada={endpointApi + item?.file.webp_url}
                                            title={item.title}
                                            fecha={item.subtitle}
                                            description={item.description}
                                            id={item.id}
                                            level={level}
                                        />
                                    )

                                }
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Noticia;
