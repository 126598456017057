import { React, useState } from "react";
import { Link } from 'react-router-dom'
import "../style/components/card.css";
import { ImagenNoticia, ArrowNews } from "../img/img";

function Card({ title, fecha, description, portada, id, level }) {
    return (
        <>
            <div id='card'>
                <Link to={"/" + level + '/noticia/' + id} className='link-card'>
                    <div className="portadaCard">
                        <img src={portada} alt="imagen noticia"></img>
                    </div>
                    <div className="bodyCard">
                        <span className="dateCard">{fecha}</span>
                        <h4>{title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: description }}></p>
                        <Link to={"/" + level + '/noticia/' + id} className="button-news">Leer más <img src={ArrowNews} alt="Flecha"></img></Link>
                    </div>
                </Link>
            </div>
        </>
    );
}


export default Card;
