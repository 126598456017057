import { React } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";

function HeaderDropdown({ items, label }) {
  return (
    <Dropdown menu={{ items }}>
      <a onClick={(e) => e.preventDefault()} href>
        <Space>
          {label}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
}

export default HeaderDropdown;
