import { React } from "react";
import "../style/components/viewActivities.css";
import { CircleGreenDark, CircleGreenLight, TriangleActivities } from "../img/img";
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import { endpointApi } from "../endpoints";

export default function ViewActivities(props) {
  const sliderActivities = {
    infinite: true,
    speed: 2000,
    autoplaySpeed: 1500,
    slidesToShow: 2,
    nextArrow: false,
    prevArrow: false,
    dots: false,
    centerPadding: '70px',
    autoplay: true,
    centerMode: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '50px',
          centerMode: true,
        }
      },
    ]
  };
  const params = useParams();
  const { level } = params;
  return (
    <>
      <div id="viewActivities">
        <div className="portadaActivities">
          <img src={props.PortadaActivities} alt='Imagen portada de actividades'></img>
          <h1>{props.TitleActivities}</h1>
        </div>
        <img src={level === 'primaria' ? CircleGreenLight : CircleGreenDark} alt='Circulo decorativo' className="circleActivities"></img>
        <div className="bodyActivities">
          <div dangerouslySetInnerHTML={{__html: props.description}}></div>
        </div>
        <img src={TriangleActivities} alt='Circulo decorativo' className="triangleActivities"></img>
        <div className="sliderActivities">
          <Slider {...sliderActivities}>
          {props.SliderFiles?.map((file) =>(
            <div className="imagenSliderActivities">
              <img src={endpointApi + file.webp_url} alt='Imagen de actividades'></img>
            </div>
          ))}
          </Slider>
        </div>
      </div>
    </>
  );
}
