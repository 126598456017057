import { React, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom'
import '../style/components/footer.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Alert from './Alert';
import { endpointSuscribeForm } from '../endpoints';
import axios from 'axios';
import { Instagram, Youtube, Facebook, Linkedin, Contact, Email, Ubication, Whatsapp, } from '../img/img'

function Footer() {
  const params = useParams();
  const { level } = params;

  useEffect(() => {
    AOS.init();
  }, [])

  const [response, setResponse] = useState([]);

  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  async function onSubmitHandler(data) {
    try {
      // setLoadingState(true);
      await axios.post(endpointSuscribeForm, { contact: data }).then((res) => {
        // setLoadingState(false);
        setResponse(res.data);
      }, (err) => err);
    } catch (error) {
      // setLoadingState(false);
      return error;
    }
  }

  return (
    <div id="footer">
      <footer>
        <div className="containerFooter">
          <div className="suscribeFooter" data-aos="fade-top" data-aos-duration="1000" data-aos-easing="ease-in-sine">
            <h3>
              Recibí las <br></br> últimas noticias, <br></br>{' '}
              <span>suscribite</span>
            </h3>
            <form className='container-suscribe-footer' onSubmit={handleSubmit(onSubmitHandler)}>
              <input {...register('email', { required: '*El campo de email es obligatorio' })} type='email' placeholder="Tu email"></input>
              {/* <span>{errors.email?.message}</span> */}
              <input
                type="submit"
                value='SUSCRIBIRSE'
                id='SUSCRIBIRSE'
              />
            </form>
            <Alert response={response} />
            <p>Seguinos en redes</p>
            <div className="redes">
              <Link to="https://www.facebook.com/saintgeorgeuruguay/?locale=es_LA" target="_blank">
                <img src={Facebook} alt="Icono redes"></img>
              </Link>
              <Link to="https://www.instagram.com/saintgeorgeuruguay/?hl=es" target="_blank">
                <img src={Instagram} alt="Icono redes"></img>
              </Link>
              <Link to="https://www.linkedin.com/company/stgeorgeuy/?originalSubdomain=uy" target="_blank">
                <img src={Linkedin} alt="Icono redes"></img>
              </Link>
              <Link to="https://vimeo.com/user99139375" target="_blank">
                <img src={Youtube} alt="Icono redes"></img>
              </Link>
            </div>
          </div>
        </div>

        <div className="containerFooter">
          <div className="primaryFooter" data-aos="fade-top" data-aos-duration="1500" data-aos-easing="ease-in-sine">
            <h4>Primaria</h4>
            <Link className="whatsappFooter infoFooter" target="_blank">
              <img alt="icono redes" src={Whatsapp}></img>
              <p>+598 2613 5338</p>
            </Link>
            <Link to='mailto:info@saintgeorge.edu.uy' className="emailFooter infoFooter" target="_blank">
              <img alt="icono redes" src={Email}></img>
              <p>info@saintgeorge.edu.uy</p>
            </Link>
            <div className="ubicationFooter infoFooter" target="_blank">
              <img alt="icono redes" src={Ubication}></img>
              <p>Juana Pereyra 1</p>
              <Link to="https://www.google.com/maps?ll=-34.898374,-56.121747&z=16&t=m&hl=es-419&gl=US&mapclient=embed&cid=7286237826820699320" target="_blank">Ver mapa</Link>
            </div>
            <Link className="contactFooter infoFooter" to={'/primaria/contacto'}>
              <img alt="icono redes" src={Contact}></img>
              <p>¡Escribinos!</p>
            </Link>
          </div>
        </div>

        <div className="containerFooter">
          <div className="secondaryFooter" data-aos="fade-top" data-aos-duration="2000" data-aos-easing="ease-in-sine">
            <h4>Secundaria</h4>
            <Link className="whatsappFooter infoFooter" target="_blank">
              <img alt="icono redes" src={Whatsapp}></img>
              <p>+598 2707 3558</p>
            </Link>
            <Link to='mailto:inscripciones@saintgeorge.edu.uy' className="emailFooter infoFooter" target="_blank">
              <img alt="icono redes" src={Email}></img>
              <p>secretaria@stgss.edu.uy</p>
            </Link>
            <div className="ubicationFooter infoFooter" target="_blank">
              <img alt="icono redes" src={Ubication}></img>
              <p>Libertad 2891</p>
              <Link to="https://www.google.com/search?tbs=lf:1,lf_ui:2&tbm=lcl&sxsrf=AJOqlzWZlu8Kfi40YMU881KoqHPGv5jjDw:1679581389336&q=st+george&rflfq=1&num=10&ved=2ahUKEwjwpdSgoPL9AhUeqJUCHcM6CIMQtgN6BAgbEAc#rlfi=hd:;si:3050262498066092427,l,CglzdCBnZW9yZ2VI2KWY1eaAgIAIWhMQABABGAAYASIJc3QgZ2VvcmdlkgELaGlnaF9zY2hvb2yqATEQASoNIglzdCBnZW9yZ2UoDjIeEAEiGp2gquO23rbHyYQD6ieB-uYlSgeXPQTeBwE9;mv:[[-34.8958511,-56.1199496],[-34.907953899999995,-56.153511099999996]]" target="_blank">Ver mapa</Link>
            </div>
            <Link to={'/secundaria/contacto'} className="contactFooter infoFooter">
              <img alt="icono redes" src={Contact}></img>
              <p>¡Escribinos!</p>
            </Link>
          </div>
        </div>
      </footer>
      <div className="subFooter">
        <span>St. George´s School / desde 1991</span>
        <span>Todos los derechos reservados St. George's School 2022</span>
      </div>
    </div>
  )
}

export default Footer;
