import { React, useState, useEffect, useContext } from "react";
import "../style/components/programa.css";
import Header from "../components/Header"
import Footer from "../components/Footer"
import { ImagenPrograma, IconPorgramaSecondary, CuartoCircleProgramDark, CuartoCircleProgramLineDark, IconPorgramaPrimary, CuartoCircleProgramLight, CuartoCircleProgramLineLight } from "../img/img";
import { useParams } from "react-router-dom";
import { Context } from "../components/Context";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";

function Programa(props) {
    const { data } = useContext(Context);
    useEffect(() => {
        AOS.init();
    }, [])
    const [optionMetodologia, setOptionMetodologia] = useState(1);
    const closeAllOpenOne = () => {
        setOptionMetodologia(2);
    };
    const closeAllOpenTwo = () => {
        setOptionMetodologia(1);
    };
    const params = useParams();
    const { level } = params;
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <>
            <Helmet>
                <title>{'Programa - Colegio St. George\'s'}</title>
                <meta name="title" content={'Programa - Colegio St. George\'s'} />
                <meta property="og:title" content={'Programa - Colegio St. George\'s'} />
                <meta property="twitter:title" content={'Programa - Colegio St. George\'s'} />
            </Helmet>
            <Header />
            <div id='programa'>
                <div className="section-one-programa">
                    <img src={level === 'primaria' ? CuartoCircleProgramLight : CuartoCircleProgramDark} alt='Fondo de ciruclos' className='circleProgramBackground'></img>
                    <div className="containerPortadaPrograma" data-aos="fade-right" data-aos-duration="1000" data-aos-easing="ease-in-sine">
                        <img src={ImagenPrograma} alt='Foto de alumnos' className="imagenPrograma"></img>
                    </div>
                    <div className="infoPrograma" data-aos="fade-left" data-aos-duration="1000" data-aos-easing="ease-in-sine">
                        <img src={level === 'primaria' ? IconPorgramaPrimary : IconPorgramaSecondary} alt='Foto de alumnos'></img>
                        <h1>Programa</h1>
                        <p>{level === 'primaria' ? "Nuestros alumnos se forman en educación bilingüe desde el inicio, cursando en paralelo dos programas curriculares de primaria." : "Nuestro Ciclo Básico y Bachillerato se rigen por el Programa de Reformulación 2006, oficial en todas las instituciones educativas del país."}</p>
                        <p>{level === 'primaria' ? "Trabajamos con los planes correspondientes al programa oficial del Uruguay y con el programa de primaria internacional “Cambridge Primary”. El programa de inglés se enriquece con seguimiento del National Curriculum de UK y nuestros años de experiencia en enseñanza bilingüe." : "Las currícula oficial se complementa con artes plásticas, artes escénicas, deportes, salidas didácticas y campamentos. La participación en estas actividades permitirá al alumno descubrir y desarrollar otros aspectos de su personalidad."}</p>
                        <p>{level === 'primaria' ? "El plan educativo también cuenta con educación física, hockey, football, atletismo, rugby, handball, danza, huerta, música, artes plásticas, teatro, paseos, visitas, exposiciones, campamentos, informática, educación sexual y educación en valores." : "A su vez, se promueve la participación en actividades de voluntariado durante el año. Entendemos que como miembros de una sociedad es importante conocer otras realidades, brindar apoyo a quienes lo necesitan y trabajar con otros por un bien común."} </p>
                    </div>
                    <img src={level === 'primaria' ? CuartoCircleProgramLineLight : CuartoCircleProgramLineDark} alt='Fondo de ciruclos' className='circleProgramBackground'></img>
                </div>
                <div className="section-two-programa">
                    <div className="metodologyContainer">
                        <h2>Metodología de Enseñanza</h2>
                        <div className="metodologyButtons">
                            <button onClick={() => { setOptionMetodologia(1); closeAllOpenTwo(); }} className={(optionMetodologia === 1 ? 'optionOneProgram selected' : 'optionOneProgram') + (level === 'primaria' ? " buttonPrimary" : " buttonSecondary")} type="button"> {level === 'primaria' ? "National Curriculum" : "Programa de Años Intermedios del Bachillerato Internacional"}</button>
                            <button onClick={() => { setOptionMetodologia(2); closeAllOpenOne(); }} className={(optionMetodologia === 2 ? 'optionOneProgram selected' : 'optionOneProgram') + (level === 'primaria' ? " buttonPrimary" : " buttonSecondary")} type="button">{level === 'primaria' ? "Programa Nacional (ANEP)" : "Bachillerato nacional"}</button>
                        </div>

                        {(optionMetodologia === 1) ? (
                            <div className="optionOneMetodolgy"
                                dangerouslySetInnerHTML={{ __html: level === 'primaria' ? data?.programs?.[0]['national_curriculum_text'] : data?.programs?.[0]['intermediate_years_text'] }}>
                            </div>

                        ) : ""}

                        {(optionMetodologia === 2) ? (
                            <div className="optionTwoMetodolgy"
                                dangerouslySetInnerHTML={{ __html: level === 'primaria' ? data?.programs?.[0]['anep_text'] : data?.programs?.[0]['national_baccalaureate_text'] }}>
                            </div>
                        ) : ""}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}


export default Programa;

