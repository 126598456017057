import { React, useEffect, useContext, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ViewActivities from "../components/ViewActivities";
import { checkLevel, htmlDecode } from "../service";
import { Context } from "../components/Context";
import "../style/components/viewActivities.css";
import {
  DeportesPrimaryImg,
  DeportesPrimarySliderOne,
  DeportesPrimarySliderTwo,
  DeportesPrimarySliderThree,
} from "../img/img";
import { endpointApi } from "../endpoints";
import { Helmet } from "react-helmet";

export default function CustomPage(props) {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const { data, setData } = useContext(Context);
  const [page, setPage] = useState();
  const params = useParams();
  const { level } = params;
  const location = useLocation();

  useEffect(() => {
    let locationData = location.pathname.split("/");

    data?.customPages?.forEach((page) => {
      if (
        checkLevel(page.educational_stage_id, level) &&
        page.url_title === locationData[2]
      ) {
        setPage(page);
      }
    });
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>{page?.name + ' - Colegio St. George\'s'}</title>
        <meta name="title" content={page?.name + ' - Colegio St. George\'s'} />
        <meta property="og:title" content={page?.name + ' - Colegio St. George\'s'} />
        <meta property="twitter:title" content={page?.name + ' - Colegio St. George\'s'} />
      </Helmet>
      <Header />
      {page ? (

        <div id="deportesPrimary">
          <ViewActivities
            PortadaActivities={endpointApi + page?.file.webp_url}
            TitleActivities={page?.name}
            SliderFiles={page?.files}
            description={page?.description}
          />
        </div>

      ) : (<div>Pagina no encontrada</div>)}
      <Footer />
    </>
  );
}
