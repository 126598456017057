import "./App.css";
import { React } from "react";
import "./style/main.css";
import PageContainer from "./PageContainer";
import { Provider } from "./components/Context";


/* Instalaciones ya realizadas

Slick 
React Router 
Frame Motion
Ant Design 
Google Map React

*/

function App() {

  return (
    <Provider>
      <div className="App">
        <PageContainer />
      </div>
    </Provider>
  );
}

export default App;