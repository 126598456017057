import { React, useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Card from "../components/Card";
import { Select } from 'antd';
import 'antd/dist/antd.css';
import "../style/site/noticias.css";
import { SemiCircleGreenDark } from "../img/img";
import { checkLevel } from "../service";
import { Context } from "../components/Context";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { endpointApi } from "../endpoints";
import { Helmet } from "react-helmet";

export default function IndexSecondary() {

    const { data, setData } = useContext(Context);
    const [news, setNews] = useState([])
    const params = useParams();
    const { level } = params;

    useEffect(() => {
        AOS.init();
    }, [])
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    function orderItems(order = '') {
        if (data?.newsItems) {
            let newsItems = data ? JSON.parse(JSON.stringify(data?.newsItems)) : []
            if (order === 'desc') {
                newsItems = newsItems?.sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
            } else if (order === 'asc') {
                newsItems = newsItems?.sort((a, b) => new Date(a.created_on) - new Date(b.created_on));
            }
            setNews(newsItems)
        }
    }

    const onChange = (value: string) => {
        orderItems(value);
    };

    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    useEffect(() => {
        orderItems('desc');
    }, [data])

    return (
        <>
            <Helmet>
                <title>{'Noticias - Colegio St. George\'s'}</title>
                <meta name="title" content={'Noticias - Colegio St. George\'s'} />
                <meta property="og:title" content={'Noticias - Colegio St. George\'s'} />
                <meta property="twitter:title" content={'Noticias - Colegio St. George\'s'} />
            </Helmet>
            <Header />
            <div className="containerNoticias">
                <div id="noticias">
                    <div className="headerNoticias">
                        <h3 data-aos="fade-right" data-aos-duration="1000" data-aos-easing="ease-in-sine">Noticias</h3>
                        <div data-aos="fade-left" data-aos-duration="1000" data-aos-easing="ease-in-sine">
                            <Select
                                showSearch
                                placeholder="Ordenar por"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                style={{
                                    border: "2px solid #1C5029",
                                    borderRadius: "8px",
                                    width: "220px",
                                }}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={[
                                    {
                                        label: 'Más Antiguo',
                                        value: 'asc'
                                    },
                                    {
                                        label: 'Más reciente',
                                        value: 'desc'
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="containerNoticias">
                        {news?.map((item) => {
                            if (checkLevel(item.educational_stage_id, level)) {
                                return (
                                    <Card
                                        portada={endpointApi + item?.file.webp_url}
                                        title={item.title}
                                        fecha={item.subtitle}
                                        description={item.description}
                                        id={item.id}
                                        level={level}
                                    />
                                )

                            }
                        })}
                    </div>
                    <img src={SemiCircleGreenDark} alt='formas de colores' className="semiCircleGreen circleFondo"></img>
                </div>
            </div>
            <Footer />
        </>
    );
}
