import { React } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Select } from 'antd';
import 'antd/dist/antd.css';
import Card from "../components/Card";
import "../style/site/cantina.css";
import { } from "../img/img";
import { Helmet } from "react-helmet";

export default function Cantina() {
    const onChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    const onSearch = (value: string) => {
        console.log('search:', value);
    };
    return (
        <>
            <Helmet>
                <title>{'Cantina - Colegio St. George\'s'}</title>
                <meta name="title" content={'Cantina - Colegio St. George\'s'} />
                <meta property="og:title" content={'Cantina - Colegio St. George\'s'} />
                <meta property="twitter:title" content={'Cantina - Colegio St. George\'s'} />
            </Helmet>
            <Header />
            <div id="cantina">
                <div className="headerCantina">
                    <h3>Cantina</h3>
                    <div>
                        <Select
                            showSearch
                            placeholder="Ordenar por"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            style={{
                                border: "2px solid #1C5029",
                                borderRadius: "8px",
                                width: "220px",
                            }}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={[

                                {
                                    label: 'Vegetariano',
                                    value: 'Vegetariano'
                                },
                                {
                                    label: 'Vegano',
                                    value: 'Vegano'
                                },
                                {
                                    label: 'Snacks',
                                    value: 'Snacks'
                                },
                                {
                                    label: 'Menú del día',
                                    value: 'Menú del día'
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="containerCantina">
                    <Card />
                    <Card />
                    <Card />
                    <Card />
                    <Card />
                    <Card />
                    <Card />
                    <Card />
                </div>
            </div>
            <Footer />
        </>
    );
}
