import { React, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import { checkLevel } from "../service";
import { endpointApi } from "../endpoints";
import { Context } from "../components/Context";
import Slider from "react-slick";
import Mailto from "./Mailto";
import "../style/components/team.css";
import { TeamUser } from "../img/img";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Team() {
    useEffect(() => {
        AOS.init();
    }, [])
    const sliderTeam = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    autoplay: true,
                }
            },
        ]
    };
    const { data, setData } = useContext(Context);
    const params = useParams();
    const { level } = params;

    return (
        <>
            <div id='team' data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">
                <h4>Nuestro Equipo</h4>
                <Slider {...sliderTeam}>
                    {data?.teachers?.map((teacher) => {
                        if (checkLevel(teacher.educational_stage_id, level)) {
                            return (
                                <div className="teamCard">
                                    <div className="containerImgCard">
                                        <img src={endpointApi + teacher?.file.webp_url} alt='Imagen de equipo de trabajo'></img>
                                    </div>
                                    <div className="bodyTeamCard">
                                        <h5>{teacher.name}</h5>
                                        <p>{teacher.job}</p>
                                        <p>{teacher.email}</p>
                                        <Mailto label="Contactarme" mailto={"mailto:" + teacher.email} target="_blank" />
                                    </div>
                                </div>
                            )

                        }
                    })}
                </Slider>
            </div>
        </>
    );
}


export default Team;
