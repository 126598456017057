import { React, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import Card from "../components/Card"
import SliderGallery from "../components/SliderGallery";
import Header from "../components/Header"
import Footer from "../components/Footer"
import { checkLevel } from "../service";
import { endpointApi } from "../endpoints";
import { Context } from "../components/Context";
import Team from "../components/Team"
import "../style/components/index.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { PortadaPrimary, PortadaSecondary, ObjetivoPrimaryIcon, ObjetivoSecondaryIcon, CircleDarkGreenLeft, CircleLightGreenLeft, CircleLightGreenLinesRight, CircleDarkGreenLinesRight, TriangleBackground } from "../img/img";
import { Helmet } from "react-helmet";

function Index(props) {
    useEffect(() => {
        AOS.init();
    }, [])
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    const sliderNoticiasIndex = {
        infinite: true,
        speed: 2000,
        autoplaySpeed: 1500,
        slidesToShow: 3,
        nextArrow: false,
        prevArrow: false,
        dots: false,
        autoplay: true,
        centerPadding: '2.5%',
        centerMode: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '80px',
                    autoplay: false,
                    centerMode: true,
                }
            },
        ]
    };
    const { data, setData } = useContext(Context);
    const params = useParams();
    const { level } = params;
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <>
            <Helmet>
                <title>{(level === 'primaria' ? 'Primaria' : 'Secundaria') + ' - Colegio St. George\'s'}</title>
                <meta name="title" content={(level === 'primaria' ? 'Primaria' : 'Secundaria') + ' - Colegio St. George\'s'} />
                <meta property="og:title" content={(level === 'primaria' ? 'Primaria' : 'Secundaria') + ' - Colegio St. George\'s'} />
                <meta property="twitter:title" content={(level === 'primaria' ? 'Primaria' : 'Secundaria') + ' - Colegio St. George\'s'} />
            </Helmet>
            <Header />
            <div id='index' className={level === 'primaria' ? "backgroundPrimary" : "backgroundSecondary"}>
                <section className="portadaIndex">
                    <img src={level === 'primaria' ? PortadaPrimary : PortadaSecondary} alt='Imagen de actividad extracurricular'></img>
                </section>
                <div className="subContainerIndex">
                    {level === 'primaria' ? (
                        <div className="noticiasIndex">
                            <h3>Noticias</h3>
                            <div className="sliderNoticiasIndex">
                                <div className="backgroundSliderIndex"></div>
                                <Slider {...sliderNoticiasIndex}>
                                    {data?.newsItems?.map((item) => {
                                        if (checkLevel(item.educational_stage_id, level)) {
                                            return (
                                                <Card
                                                    portada={endpointApi + item?.file.webp_url}
                                                    title={item.title}
                                                    fecha={item.subtitle}
                                                    description={item.description}
                                                    id={item.id}
                                                    level={level}
                                                />
                                            )

                                        }
                                    })}
                                </Slider>


                            </div>
                            <img src={level === 'primaria' ? CircleLightGreenLeft : CircleDarkGreenLeft} alt='icono' className="circleHome"></img>
                            <div className="objetivo" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">
                                <img src={level === 'primaria' ? ObjetivoPrimaryIcon : ObjetivoSecondaryIcon} alt='icono'></img>
                                <h3>Nuestro objetivo es <span>formar personas críticas, empáticas y reflexivas,</span> sobre la base de un sólido sustento académico y humanitario.</h3>
                            </div>
                        </div>
                    ) : ''}
                    <SliderGallery />
                    <img src={level === 'primaria' ? CircleLightGreenLinesRight : CircleDarkGreenLinesRight} alt='icono' className="circleHomeLines"></img>
                    <img src={TriangleBackground} alt='Fondo gris' className="triangleHome"></img>
                    <Team />
                </div>
            </div>
            <Footer />
        </>
    );
}


export default Index;

