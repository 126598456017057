import { React, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../style/components/viewActivities.css";
import { ObjetivoSecondaryIcon, ArteSecondaryImagen } from "../../img/img";
import { Helmet } from "react-helmet";

export default function ArtePrimary(props) {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <>
      <Helmet>
        <title>{'Arte - Colegio St. George\'s'}</title>
        <meta name="title" content={'Arte - Colegio St. George\'s'} />
        <meta property="og:title" content={'Arte - Colegio St. George\'s'} />
        <meta property="twitter:title" content={'Arte - Colegio St. George\'s'} />
      </Helmet>
      <Header />
      <div id="arteSecondary">
        <img src={ArteSecondaryImagen} alt='collage' className="imagenPortadaArte"></img>
        <div>
          <img src={ObjetivoSecondaryIcon} alt='icono'></img>
          <h1>¿Por qué <span>integrar a la currícula</span> liceal la opción artística?</h1>
          <p> La respuesta es ineludible: porque por naturaleza el ser humano tiene impreso en su ADN el sentido de lo artístico. Tanto en la expresión plástica como escénica se trabaja desde la experimentación abordando temáticas diversas y utilizando técnicas que enriquezcan y desarrollen la percepción de los estudiantes. La sensibilización y la participación crítica es el principal objetivo de esta área.</p>
          <p>Es en estos entornos en donde el adolescente se encuentra con sus pares en un clima diferente al común de las asignaturas curriculares; se propicia un relacionamiento donde el vínculo entre ellos se desarrolla desde la cooperación y el trabajo colectivo, fomentando de esta manera valores como el respeto, la tolerancia, el compañerismo, el compromiso, la responsabilidad y la solidaridad. La experiencia vivencial creativa y su producción, serán parte integral del proceso de enseñanza – aprendizaje.</p>
        </div>
      </div>
      <Footer />
    </>
  );
}
