import { React, useState, useContext, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "../style/components/header.css";
import { Logo } from "../img/img";
import { checkLevel, getCustomPageSectionName } from "../service";
//import type { MenuProps } from "antd";
import 'aos/dist/aos.css';
import { Context } from "../components/Context";
import HeaderDropdown from "./HeaderDropdown";
function Header(props) {
  const { data } = useContext(Context);
  const [openMenu, setOpenMenu] = useState(true);
  const params = useParams();
  const { level } = params;
  const location = useLocation();

  useEffect(() => {
    setOpenMenu(true)
  }, [location.pathname]);

  const itemsExtracurriculares = data?.customPages?.map((page) => {
    if (checkLevel(page.educational_stage_id, level) && getCustomPageSectionName(page.custom_page_section_id) === 'extracurriculares' && page.independent_in_header === '0') {
      return {
        key: page.id,
        label: (
          <Link
            rel="noopener noreferrer"
            to={'/' + level + '/' + page.url_title}>
            {page.name}
          </Link>
        )
      }
    }
  });

  if (level === 'secundaria') {
    itemsExtracurriculares?.push({
    //   key: 'ingles',
    //   label: (
    //     <Link to={"/" + level + "/ingles"}>Inglés</Link>
    //   )
    // }, {
      key: 'artes',
      label: (
        <Link to={"/" + level + "/artes"}>Artes</Link>
      )
    })
  }

  const itemsProyectosInterdisciplinarios = data?.customPages?.map((page) => {
    if (checkLevel(page.educational_stage_id, level) && getCustomPageSectionName(page.custom_page_section_id) === 'proyectos interdisciplinarios' && page.independent_in_header === '0') {
      return {
        key: page.id,
        label: (
          <Link
            rel="noopener noreferrer"
            to={'/' + level + '/' + page.url_title}>
            {page.name}
          </Link>
        )
      }
    }
  });


  /*******************************/
  /*
  const itemsInfo: MenuProps['items'] = [
    {
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
          Cantina
        </a>
      ),
      key: '0',
    },
    {
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
           + Info
        </a>
      ),
      key: '1',
    }
  ];
  */
  /*******************************/


  return (
    <>
      <div id="header" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
        <header>
          <Link to="/">
            <img src={Logo} alt="Logo St George's School"></img>
          </Link>
          <nav className="desktopElement">
            <Link to={"/" + level}>INICIO</Link>
            {level === 'primaria' ? (<Link to={"/" + level + "/noticias"}>NOTICIAS</Link>):''}
            <Link to={"/" + level + "/galeria"}>GALERÍA</Link>
            <Link to={"/" + level + "/programa"}>PROGRAMAS</Link>
            <HeaderDropdown items={itemsExtracurriculares} label='EXTRACURRICULARES' />
            <HeaderDropdown items={itemsProyectosInterdisciplinarios} label='PROYECTOS INTERDISCIPLINARIOS' />
            {data?.customPages?.map((page) => {
              if (checkLevel(page.educational_stage_id, level) && page.independent_in_header === '1') {
                return (
                  <Link
                    rel="noopener noreferrer"
                    to={'/' + level + '/' + page.url_title}>
                    {page.name}
                  </Link>
                )
              }
            })}
            <Link to={"/" + level + "/nosotros"}>NOSOTROS</Link>
            {/* <Link to={"/" + level + "/educa"} className="educa">
              EDUCÁ
            </Link> */}

          </nav>

          <button onClick={() => { setOpenMenu(!openMenu); }} className={openMenu === true ? 'burgerMenu mobileElement' : 'burgerMenu mobileElement open'}>
            <span></span>
            <span></span>
            <span></span>
          </button>
          <nav className="mobileElement">
            <Link to={"/" + level}>INICIO</Link>
            <Link to={"/" + level + "/nosotros"}>NOSOTROS</Link>
            {level === 'primaria' ? (<Link to={"/" + level + "/noticias"}>NOTICIAS</Link>):''}
            <Link to={"/" + level + "/galeria"}>GALERÍA</Link>
            <Link to={"/" + level + "/programa"}>PROGRAMAS</Link>
            <HeaderDropdown items={itemsExtracurriculares} label='EXTRACURRICULARES' />
            <HeaderDropdown items={itemsProyectosInterdisciplinarios} label='PROYECTOS INTERDISCIPLINARIOS' />
            {data?.customPages?.map((page) => {
              if (checkLevel(page.educational_stage_id, level) && page.independent_in_header === '1') {
                return (
                  <Link
                    rel="noopener noreferrer"
                    to={'/' + level + '/' + page.url_title}>
                    {page.name}
                  </Link>
                )
              }
            })}
            {/* <Link to={"/" + level + "/educa"} className="educa">
              EDUCÁ
            </Link> */}
          </nav>
        </header>
      </div>
      <div className="headerDummy"></div>
    </>
  );
}

export default Header;
