function getEducationalStageName(id) {
  if (id === '1') {
    return 'primaria'
  } else if (id === '2') {
    return 'secundaria'
  } else if (id === '3') {
    return 'ambas'
  }
}

function checkLevel(educational_stage_id, level) {
  const educational_stage_name = getEducationalStageName(educational_stage_id)
  return educational_stage_name === 'ambas' || educational_stage_name === level
}

function checkGalleryFileCategoryLevel(gallery_file_categorie_id, categories, level) {
  let category = categories.filter((category) => (gallery_file_categorie_id === category.id))
  return checkLevel(category[0]?.educational_stage_id, level)
}

function getCustomPageSectionName(id) {
  if (id === '1') {
    return 'extracurriculares'
  } else if (id === '2') {
    return 'proyectos interdisciplinarios'
  }
}

function htmlDecode(input) {
  var e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export {
  getEducationalStageName,
  checkGalleryFileCategoryLevel,
  checkLevel,
  getCustomPageSectionName,
  htmlDecode
}