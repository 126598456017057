const endpointApi = 'https://api.stgeorges.moonideas.com'
// const endpointApi = 'https://api.stgss.edu.uy'
const endpointFrontData = `${endpointApi}/site/get-front-data`;
const endpointSuscribeForm = `${endpointApi}/contacts/subscribe`;
const endpointContactForm = `${endpointApi}/contacts/add`;
export {
  endpointApi,
  endpointFrontData,
  endpointSuscribeForm,
  endpointContactForm
}