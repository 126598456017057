import { React, useEffect, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import "./style/main.css";
import { endpointFrontData } from "./endpoints";
import Home from "./site/Home";
import Index from "./site/Index";
import Noticias from "./site/Noticias";
import Nosotros from "./site/Nosotros";
import Galeria from "./site/Galeria";
import Programa from "./site/Programa";
import Contact from "./site/Contact";
import Cantina from "./site/Cantina";
import Noticia from "./site/Noticia";
import CustomPage from "./site/CustomPage";
import ArteSecondary from "./site/Secondary/ArteSecondary";
import InglesSecondary from "./site/Secondary/InglesSecondary";
import axios from "axios";
import { Context } from "./components/Context";

function PageContainer() {
  const { data, setData } = useContext(Context);

  const getData = async () => {
    try {
      const response = await axios.post(
        endpointFrontData
      );
      return response;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    const getServerData = async () => {
      const response = await getData();
      setData(response.data);
      console.log(response.data);
    };
    getServerData();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:level" element={<Index />} />
      <Route path="/:level/programa" element={<Programa />} />
      <Route path="/:level/noticias" element={<Noticias />} />
      <Route path="/:level/nosotros" element={<Nosotros />} />
      <Route path="/:level/galeria" element={<Galeria />} />
      <Route path="/:level/contacto" element={<Contact />} />
      <Route path="/:level/cantina" element={<Cantina />} />
      <Route path="/:level/noticia/:id" element={<Noticia />} />
      {data?.customPages?.map((page) => (
        <Route path={"/:level/" + page?.url_title} element={<CustomPage />} />
      ))}
      <Route path="/:level/artes" element={<ArteSecondary />} />
      <Route path="/:level/ingles" element={<InglesSecondary />} />
    </Routes>
  );
}

export default PageContainer;
