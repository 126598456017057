import { React, useEffect } from "react";
import Footer from "../components/Footer"
import Header from "../components/Header"
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../style/site/nosotros.css";
import { useParams } from "react-router-dom";
import { PortadaAboutUs, PortadaBottomAboutUs, CircleGreenDark, CuartoCircleLineDark, CircleGreenLight, CircleLightGreenLinesRight, CircleDarkGreenLinesRight, } from "../img/img";
import { Helmet } from "react-helmet";

export default function Nosotros() {
    const params = useParams();
    const { level } = params;
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <>
            <Helmet>
                <title>{'Nosotros - Colegio St. George\'s'}</title>
                <meta name="title" content={'Nosotros - Colegio St. George\'s'} />
                <meta property="og:title" content={'Nosotros - Colegio St. George\'s'} />
                <meta property="twitter:title" content={'Nosotros - Colegio St. George\'s'} />
            </Helmet>
            <Header />
            <div id='nosotros'>
                <img src={level === 'primaria' ? CircleLightGreenLinesRight : CircleDarkGreenLinesRight} alt='formas de colores' className="circleCuarto circleFondo"></img>
                <div className="section-one-nosotros">
                    <img src={PortadaAboutUs} alt='Imagen antigua de la escuela' className="mobileElement"></img>
                    <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-easing="ease-in-sine"> A <br></br> childhood <br></br> <span>dream</span></h1>
                    <img src={PortadaAboutUs} alt='Imagen antigua de la escuela' className="desktopElement" data-aos="fade-left" data-aos-duration="1000" data-aos-easing="ease-in-sine"></img>
                </div>
                <div className="section-two-nosotros">
                    <iframe src="https://player.vimeo.com/video/475709246?h=69a60a860f" width="740" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen className="desktopElement"></iframe>
                    <img src={level === 'primaria' ? CircleGreenLight : CircleGreenDark} alt='formas de colores' className="circleGreen circleFondo"></img>
                    <div>
                        <h3 data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine">La idea de crear un centro educativo surgió durante la infancia. </h3>
                        <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">Nevers Díaz Pasquet y María Isabel Varela Surraco fueron compañeras desde primaria. Años después, ya en el liceo, estudiaban juntas. Una tarde, previo a un escrito, prometieron formarse como docentes y, luego de adquirir cierta experiencia, crear un colegio de muy buena calidad de enseñanza, donde aprender fuera motivante y divertido, donde los niños, rodeados de amor, pudieran desarrollar sus aptitudes y formar sus opiniones, sin repetir lecciones mecánicamente.</p>
                    </div>
                    <iframe src="https://player.vimeo.com/video/475709246?h=69a60a860f" width="740" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen className="mobileElement"></iframe>
                </div>
                <div className="section-three-nosotros">
                    <h2 data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine">Ese fue el camino que tomaron.</h2>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">Junto a su familia, Nevers había fundado un jardín de infantes -Let´s Play- en 1987. Unos años después, María invitó a su amiga de la infancia para cumplir aquella promesa. Con el apoyo de las maravillosas familias de aquel jardín, en 1991 nació el colegio Saint George en la misma casa que habitó María durante su infancia. Aquella en donde habían prometido trabajar juntas para lograr una institución donde la excelencia académica y la calidad humana fueran un sello de identidad.</p>
                    <h2 data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine">Una realidad que se afianza</h2>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">Con el tiempo, se acercaron varias familias que creyeron en el proyecto y compartieron la misma visión de la educación. Familias que son conscientes de la dedicación y el cuidado que hay que brindar al pequeño, y que además comparten los principios de cómo ese niño debe crecer, formarse como persona en todos sus aspectos, adquirir las herramientas para desarrollarse con seguridad y con total fluidez en inglés.</p>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">La educación es también una apuesta a descubrir el mundo del conocimiento y a estimular el desarrollo del pensamiento crítico de los alumnos. Estos son conceptos que se aplican en el colegio apoyados en los últimos programas desarrollados en Gran Bretaña y Estados Unidos.</p>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">Los docentes son cuidadosamente seleccionados para que impulsen a los niños con cariño, llevándolos a sentirse seguros y a trabajar con independencia. La idea es motivarlos a preguntar y brindarles los medios para que encuentren las respuestas y descubran el vasto mundo del conocimiento con alegría y fluidez, al tiempo que afianzan su personalidad. Poder hacerlo en los dos idiomas indistintamente, les brinda una herramienta que les abre el mundo y contribuye al desarrollo de sus potencialidades.</p>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">El colegio sigue caminando, innovando y creciendo. María y Nevers han dejado un legado que sigue vivo en la familia, son sus hijos quienes siguen adelante, manteniendo el espíritu de siempre.</p>
                    <h3 data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine">Saint George´s Nursery, Primary y Secondary son una realidad que se afianza, año a año, como comunidad educativa.</h3>
                </div>
                <img src={PortadaBottomAboutUs} alt="Imagen evento fin de año"></img>
                <img src={level === 'primaria' ? CircleGreenLight : CircleGreenDark} alt='formas de colores' className="semiCircleGreen circleFondo"></img>
                <img src={level === 'primaria' ? CircleLightGreenLinesRight : CircleDarkGreenLinesRight} alt='formas de colores' className="semiCircleLineGreen circleFondo"></img>
            </div>
            <Footer />
        </>
    );
}

