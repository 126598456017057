import { React, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../style/components/viewActivities.css";
import {
    InglesSecondaryImg, Cambridge
} from "../../img/img";
import { Helmet } from "react-helmet";

export default function InglesSecondary(props) {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0 });
    }, []);


    return (
        <>
            <Helmet>
                <title>{'Inglés - Colegio St. George\'s'}</title>
                <meta name="title" content={'Inglés - Colegio St. George\'s'} />
                <meta property="og:title" content={'Inglés - Colegio St. George\'s'} />
                <meta property="twitter:title" content={'Inglés - Colegio St. George\'s'} />
            </Helmet>
            <Header />
            <div id="InglesSecondary">
                <div className="portadaActivities">
                    <img src={InglesSecondaryImg} alt='Imagen portada de actividades'></img>
                </div>
                <div className="bodyActivities">
                    <p>Nuestra institución ofrece un plan bilingüe que combina las tendencias pedagógicas actuales, atendiendo a la mejor formación de los estudiantes para su futuro desarrollo tanto académico como laboral.</p>

                    <div className="cambridgeImage">
                        <img src={Cambridge} alt='Logo de Cambridge'></img>
                        <p>
                            Nuestra apuesta al bilingüismo apunta a la certificación de exámenes internacionales respladados por la <span>Universidad de Cambridge</span>
                        </p>
                    </div>
                    <div class="tableEnglish">
                        <thead>
                            <tr>
                                <th>7th  Year</th>
                                <th>8th Year</th>
                                <th>9th Year</th>
                                <th>Fourth Year</th>
                                <th>Fifth Year</th>
                                <th>Sixth Year</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>English</td>
                                <td>English</td>
                                <td>English</td>
                                <td>English</td>
                                <td>English</td>
                                <td>English</td>
                            </tr>
                            <tr>
                                <td>Maths</td>
                                <td>Maths</td>
                                <td>Maths</td>
                                <td>Maths</td>
                                <td>Elective workshops</td>
                                <td>Elective workshops</td>
                            </tr>
                            <tr>
                                <td>ICT</td>
                                <td>ICT</td>
                                <td>ICT</td>
                                <td>ICT</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Science</td>
                                <td>Science</td>
                                <td>Science</td>
                                <td>Science</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Elective workshops</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Portugués</td>
                                <td>Portugués</td>
                                <td>Portugués</td>
                                <td>Portugués</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </div>

                    <h2>IGCSE & A LEVELS – CAMBRIDGE INTERNATIONAL</h2>
                    <p>En el transcurso de los primeros cuatro años de educación secundaria, se prepara a los estudiantes para presentarse a los exámenes de IGCSE (International General Certificate of Secondary Education).</p>
                    <p>Los exámenes de Cambridge IGCSE son de los más prestigiosos para jóvenes entre 14 y 16 años, garantizándoles formación de alto nivel internacional. Esta preparación que culmina en cuarto año cuando los estudiantes rinden los exámenes internacionales, les permite recibir un certificado internacional por las asignaturas a las que se presentan.</p>
                    <p>Es importante destacar que estos exámenes promueven el aprendizaje centrado en el alumno, quien aprende de forma interdisciplinaria y a través de la investigación. De este  modo se apunta a desarrollar el pensamiento creativo y la solución de problemas a través del método deductivo.</p>

                    <h2>CAMBRIDGE B2 FIRST, C1 ADVANCED  Y C2 PROFICIENCY</h2>
                    <p>Estos exámenes de la Universidad de Cambridge (Cambridge Assessment) son ampliamente reconocidos tanto en nuestro medio como internacionalmente, contando con gran aceptación y demanda a nivel empresarial.</p>
                    <p>Nuestra institución ofrece la preparación del examen de B2 First, el cual se rinde en tercer año y C1 Advanced y C2 Proficiency, los cuales se rinden en quinto y sexto respectivamente.</p>
                    <p>En St. George 's Secondary School reconocemos la importancia y validez de dichos exámenes y atendemos a la diversidad de los estudiantes, especialmente aquellos que no poseen un nivel bilingüe. En estos casos se realizan tutorías en los niveles mencionados con el objetivo de que cada estudiante logre presentarse a exámenes internacionales acordes a su proceso individual.</p>

                    <h2>Elective Workshops</h2>
                    <p>Son talleres semestrales optativos para estudiantes de 1º, 2º y 3º año de Bachillerato. Los mismos tienen como objetivo brindar a los estudiantes un espacio de uso de las lenguas extranjeras a la vez que adquieren competencias necesarias para su formación. Además,  se fomenta la autonomía y la motivación de los estudiantes dándoles la opción de elegir talleres acordes a sus intereses.</p>
                </div>
            </div>
            <Footer />
        </>
    );
}
