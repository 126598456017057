import { React, useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { endpointApi } from "../endpoints";
import { checkLevel, checkGalleryFileCategoryLevel } from "../service";
import { Context } from "../components/Context";
import { Select } from 'antd';
import 'antd/dist/antd.css';
import "../style/site/galeria.css";
import { ImagenGaleria } from "../img/img";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";

export default function Galeria() {
    const { data, setData } = useContext(Context);
    const [filterBy, setFilterBy] = useState('todos')
    const [categories, setCategories] = useState([])
    const [files, setFiles] = useState([])
    const params = useParams();
    const { level } = params;

    function orderItems(order = '') {
        if (data?.galleryFiles) {
            let galleryFiles = data ? JSON.parse(JSON.stringify(data?.galleryFiles)) : []
            if (order === 'desc') {
                galleryFiles = galleryFiles?.sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
            } else if (order === 'asc') {
                galleryFiles = galleryFiles?.sort((a, b) => new Date(a.created_on) - new Date(b.created_on));
            }
            setFiles(galleryFiles)
        }
    }

    useEffect(() => {
        orderItems('desc');
        let todos = [{ label: 'Todos', value: 'todos' }]
        let galleryCategories = data?.galleryFileCategories?.filter((category) => {
            if (checkLevel(category.educational_stage_id, level)) {
                return true
            }
        })
        galleryCategories = galleryCategories?.map((category) => {
            if (checkLevel(category.educational_stage_id, level)) {
                return ({
                    label: category.name,
                    value: category.id
                })
            }
        })
        if (galleryCategories) {
            setCategories([...todos, ...galleryCategories])
        }
    }, [data])

    useEffect(() => {
        AOS.init();
    }, [])
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    const onChange = (value: string) => {
        // orderItems(value);
        setFilterBy(value)
    };

    // const onSearch = (value: string) => {
    //     console.log('search:', value);
    // };

    return (
        <>
            <Helmet>
                <title>{'Galería - Colegio St. George\'s'}</title>
                <meta name="title" content={'Galería - Colegio St. George\'s'} />
                <meta property="og:title" content={'Galería - Colegio St. George\'s'} />
                <meta property="twitter:title" content={'Galería - Colegio St. George\'s'} />
            </Helmet>
            <Header />
            <div className="containerGaleria">
                <div id="galeria">
                    <div className="headerNoticias">
                        <h3 data-aos="fade-right" data-aos-duration="1000" data-aos-easing="ease-in-sine">Galería</h3>
                        <div data-aos="fade-left" data-aos-duration="1000" data-aos-easing="ease-in-sine">
                            <Select
                                // showSearch
                                placeholder="Filtrar por"
                                optionFilterProp="children"
                                onChange={onChange}
                                // onSearch={onSearch}
                                style={{
                                    border: "2px solid #1C5029",
                                    borderRadius: "8px",
                                    width: "220px",
                                }}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={
                                    categories
                                }
                            />
                        </div>
                    </div>
                    <div className="containerGaleria">
                        {files?.map((file) => {
                            if ((filterBy === 'todos' && checkGalleryFileCategoryLevel(file.gallery_file_categorie_id, data?.galleryFileCategories, level))
                                || file.gallery_file_categorie_id === filterBy) {
                                return (
                                    <div className="containerImgGaleria">
                                        <img src={endpointApi + file?.file.webp_url} alt='imagen galeria de actividades'></img>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
