import { React, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "../style/components/sliderGallery.css";
import { endpointApi } from "../endpoints";
import { Context } from "../components/Context";
import { checkLevel } from "../service";
import { ImagenSliderGallery } from "../img/img";

function SliderGallery(props) {
    const { data, setData } = useContext(Context);
    const params = useParams();
    const { level } = params;

    const sliderGallery = {
        infinite: true,
        speed: 2500,
        autoplaySpeed: 1500,
        slidesToShow: 3,
        nextArrow: false,
        prevArrow: false,
        dots: false,
        autoplay: true,
        centerPadding: '80px',
        centerMode: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 1,
                    autopay: false,
                    slidesToScroll: 1,
                }
            },
        ]
    };
    return (
        <>
            <div id='sliderGallery'>
                <Slider {...sliderGallery}>
                    {data?.galleryFiles?.map((file) => {
                        if (checkLevel(file.educational_stage_id, level)) {
                            return (
                                <div className="containerImgGaleria">
                                    <img src={endpointApi + file?.file.webp_url} alt='Imagen del colegio'></img>
                                </div>
                            )

                        }
                    })}
                </Slider>
            </div>
        </>
    );
}


export default SliderGallery;